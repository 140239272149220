import { render, staticRenderFns } from "./ownPurchase.vue?vue&type=template&id=15779512&scoped=true&"
import script from "./ownPurchase.vue?vue&type=script&lang=js&"
export * from "./ownPurchase.vue?vue&type=script&lang=js&"
import style0 from "./ownPurchase.vue?vue&type=style&index=0&id=15779512&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15779512",
  null
  
)

export default component.exports