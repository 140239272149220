<template>
	<!-- 租赁订单 -->
	<div>
		<!-- 搜索栏 & 标签页 -->
		<order-search :start="startDate" :end="endDate" @handle-start="handleStart" @handle-end="handleEnd"
			@handle-search="getData">
		</order-search>
		<order-tabs v-model="tab" :tabs-list="tabList" @tab-click="getData">
		</order-tabs>
		<!-- 表格 -->
		<center-order-table table-title="购买订单" :table-list="orderTableList">
			<!-- 价格 -->
			<template #price="{price}">
				<div>{{"¥ "+ price}}</div>
			</template>
			<!-- 实付款 -->
			<template #payment="{price}">
				<div>{{"¥ "+ price}}</div>
			</template>
			<!-- 交易状态 -->
			<template #status="{status, id}">
				<div>{{status | ostatus}}</div>
				<router-link :to="{
                        name: 'clothesPay',
                        query:{id}
                    }">
					订单详情
				</router-link>
			</template>
			<!-- 操作 -->
			<template #handle="{row}">
				<!-- 我的订单 -->
				<div>
					<!-- 去支付 -->
					<router-link :to="{
                            name: 'clothesPay',
                            query:{id: row.orderID}
                        }" class="center-lease-handle" v-if="row.orderStatus==0">
						去支付
					</router-link>
					<a href="javascript:;" v-if="row.orderStatus == 0" @click="handleStatus(row.orderID, -1)">
						取消订单
					</a>
					<!-- 	<div
					    class="center-lease-handle"
					    v-if="row.orderStatus==0"
						@click="handleStatus(row.orderID, -1)"
						>
					    取消订单
					</div> -->
					<!-- 发货
					<a href="javascript:;" class="center-lease-handle" v-if="row.orderStatus==1"
						@click="handleStatus(row.orderID, 2)">
						发货
					</a>-->
          <!-- 退款 -->
          <a href="javascript:;" class="center-lease-handle" v-if="row.orderStatus==2"
             @click="handleStatus(row.orderID, 3)">
            退款
          </a>
          <!-- 发货 -->
          <a href="javascript:;" class="center-lease-handle" v-if="row.orderStatus==2"
             @click="handleStatus(row.orderID, 9)">
            完成订单
          </a>
				</div>
			</template>
		</center-order-table>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations,
		mapActions
	} from 'vuex'
	import order from '@/mixins/centerOrder'

	export default {
		filters: {
			// 订单状态过滤器
			ostatus(val) {
				switch (val) {
					case 0:
						return "待付款";
						break;
					case 1:
						return "待发货";
						break;
					case 2:
						return "已发货";
						break;
          case 3:
            return "退款中";
            break;
					case 9:
						return "已完成";
						break;
					case -1:
						return "取消";
						break;
					case -2:
						return "已退款";
						break;
				}
			},
		},
		mixins: [order],
		data() {
			return {
				tab: "-3",
				tabList: [{
						value: "-3",
						label: "全部",
					},
					{
						value: "0",
						label: "待付款",
					},
					{
						value: "1",
						label: "待发货",
					},
					{
						value: "2",
						label: "已发货",
					},
          {
            value: "3",
            label: "退款中",
          },
					{
						value: "9",
						label: "已完成",
					},
					{
						value: "-1",
						label: "已取消",
					},
          {
            value: "-2",
            label: "已退款",
          }
				]
			}
		},
		computed: mapState({
			user_id: state => state.user.user_id,
			orderTableList: state => state.order.orderTableList
		}),
		methods: {
			...mapMutations([
				'handleLoading',
				'setCenterOrder'
			]),
			...mapActions([
				'getCenterOrder',
				'handleOrderStatus'
			]),
			getParams() {
				let userID = this.user_id,
					fromTime = this.startDate,
					toTime = this.endDate,
          orderType = 2,
					orderStatus = this.tab == '-3' ? '' : this.tab;
				return this.$qs.stringify({
					userID,
					fromTime,
					toTime,
					orderStatus,
          orderType,
					type: 2
				})
			},
			getData() {
				let url = 'usercenter/leaseorder',
					params = this.getParams();
				this.getCenterOrder({
					url,
					params
				})
			},
			getStatusParams(orderID, status) {
				let userID = this.user_id,
					url = '/usercenter/leaseOrderStatus',
					params = this.$qs.stringify({
						userID,
						orderID,
						status
					});
				return {
					url,
					params
				}
			},
			handleStatus(orderID, status) { // 改变订单状态
				let {
					url,
					params
				} = this.getStatusParams(orderID, status);

				this.$confirm(`是否改变订单状态？`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.handleLoading(true);
					return this.handleOrderStatus({
						url,
						params
					})
				}).then(res => {
					this.$message({
						showClose: true,
						message: res.data.message,
						type: res.data.code == 200 ? 'success' : 'error'
					})
					this.getData()
				}).catch(() => {}).finally(() => {
					this.handleLoading(false);
				})
			}
		},
		beforeRouteEnter(to, from, next) {
			next(vm => {
				vm.getData();
			})
		},
		beforeRouteLeave(to, from, next) {
			this.setCenterOrder([]);
			next();
		}
	}
</script>

<style scoped>
	.center-lease-handle {
		display: block;
	}

	.center-lease-handle+.center-lease-handle {
		margin-top: 10px;
	}
</style>
